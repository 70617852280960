`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import ModalDialog from 'javascripts/vendors/modal_dialog'`
`import { time_entry_is_int } from 'javascripts/components/time_entries/time_validation'`
`import Utils from 'javascripts/main/utils'`
`import validate_custom_parameters from 'javascripts/rails.validations.customValidators.js.coffee'`
`import removeCkeditorInstancesFromContainer from 'javascripts/vendors/remove_ckeditor_instance'`
`import { reinitialize_ckeditor_instance } from 'javascripts/main/reinitialize_ckeditor'`
`import setTableScroll from 'javascripts/components/tables/set_table_scroll'`

init_new_time_entry_modal_form = ->
  $('@time-entry-form-btn').off('ajax:success').on 'ajax:success', (e, data, status, xhr) ->
    e.preventDefault()

    ClientSideValidations.callbacks.element.after = (element, eventData) ->
      if element.parents('@time_entry_form').length > 0
        validate_custom_parameters($('@time-entry-parameters-list-container'))

    perform_json_response(data)
    if data? && !data.error?
      ModalDialog.new('@time-entry-form-modal-dialog')
      ModalDialog.list["@time-entry-form-modal-dialog"].show()
      init_ckeditor_instances()
      init_time_entry_block()

init_time_entry_block = ->
  $('@time-entry-form-modal-dialog form').off('ajax:success').on 'ajax:success', (e, data, status, xhr) ->
    e.preventDefault()
    perform_json_response(data)
    if data? && !data.error?
      init_new_time_entry_modal_form()
      init_show_time_entries_list()
      init_destroy_action()
      init_scroll()
      $('@time-entry-form-modal-dialog @modal-dialog-close').click()

  $(document).on 'ajax:before', '@time-entry-form-modal-dialog form', (e, data) ->
    e.preventDefault()

    containers = $(@).find('.form-modal-custom-parameters-item.ftdatetime')
    containers.each (index, container) ->
      Utils.construct_hidden_datetime_param($(container), 'time_entry')
    $('@time-entry-submit').prop('disabled', true)

    parameters_valid = validate_custom_parameters($('@time-entry-parameters-list-container'))

    if !parameters_valid
      $('@time-entry-submit').prop('disabled', false)
      return false

    if time_entry_is_int($(@).find('#time_entry_formatted_spent_time'))
      return false

    if $(e.target).data('skip-submit-request')
      $('@time-entry-form-modal-dialog @modal-dialog-close').click()
      return false

  $(document).on 'ajax:success', '@time-entry-form-modal-dialog form', (e, data) ->
    if data && data.error
      $('@time-entry-submit').prop('disabled', false)

init_show_time_entries_list = ->
  $('@time-entries-list').off('ajax:success').on 'ajax:success', (e, data, status, xhr) ->
    e.preventDefault()
    perform_json_response(data)
    if data? && !data.error?
      ModalDialog.new('@time-entries-list-modal-dialog')
      ModalDialog.list["@time-entries-list-modal-dialog"].show()
      init_time_entries_list_and_modal_form()
      init_destroy_action()
      init_scroll()

init_destroy_action = ->
  $('@delete-issue-time-entry').off('ajax:success').on 'ajax:success', (e, data, status, xhr) ->
    e.preventDefault()
    perform_json_response(data)
    if data? && !data.error?
      init_new_time_entry_modal_form()
      init_show_time_entries_list()
      init_scroll()

init_ckeditor_instances = ->
  init_ckeditor_destroy_callback()
  form = $('@time-entry-form-modal-dialog').find('form')
  instance_name = form.find('textarea').attr('id')
  instance = CKEDITOR.instances[instance_name]

  if instance? && instance.status != 'ready'
    instance.config.toolbar = "fttext_parameters"
    reinitialize_ckeditor_instance(instance, instance_name)

init_ckeditor_destroy_callback = ->
  $('@time-entry-form-modal-dialog').off('hide_modal').on 'hide_modal', ->
    removeCkeditorInstancesFromContainer($('@time-entry-form-modal-dialog'))

init_time_entries_list_and_modal_form = ->
  init_new_time_entry_modal_form()
  init_show_time_entries_list()

init_scroll = ->
  setTableScroll('time-entries')

  scrollableMaxHeight = 500
  scrollable = $('@vertical-scroll-container')
  scrollEnabled = scrollable.hasClass('mCustomScrollbar')

  if scrollable.height() < scrollableMaxHeight
    scrollable.mCustomScrollbar('destroy') if scrollEnabled
  else
    if scrollEnabled
      scrollable.mCustomScrollbar('update')
    else
      scrollable.mCustomScrollbar({
        theme: 'minimal-dark',
        scrollInertia: 0
      })

`export {init_new_time_entry_modal_form, init_show_time_entries_list, init_time_entries_list_and_modal_form }`
